/* eslint-disable no-await-in-loop,no-plusplus,no-promise-executor-return */
import { CachePolicies, useFetch } from 'use-http';
import { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { StringParam, useQueryParam } from 'use-query-params';
import { routes } from '@/webapi/routes';
import { Pages } from '@/webapi/pages';
import { AccountContext } from '@/features/account-context';
import { maybe } from '@/features/details/utils';

export function useAwaitActiveSubscription() {
  const [tokenRef] = useQueryParam(`s`, StringParam); //  charge_id query param also available here

  const accountCtx = useContext(AccountContext);
  const { post, error } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
    },
  }));
  useEffect(() => {
    let attempts = 0;
    (async () => {
      while (attempts++ < 60) {
        try {
          const resp = await post(routes.awaitActiveSubscription(), {
            value: decodeURIComponent(tokenRef),
          });
          if (resp?.value === `PENDING`) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } else if (resp?.value === `ACTIVE`) {
            const sd = await accountCtx.fetchUserContext();
            // @ts-ignore
            maybe(() => window.fpr(`referral`, { email: sd?.email }));
            navigate(
              `${Pages.DASHBOARD}${resp?.alias ? `?al=${resp?.alias}` : ``}`,
            );
            break;
          }
        } catch (e) {
          console.log(e);
          navigate(Pages.LOGIN);
          break;
        }
      }
    })();
  }, []);

  return {
    error,
  };
}
