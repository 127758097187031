import React, { FC } from 'react';
import styled from 'styled-components';
import { useAwaitActiveSubscription } from '@/features/signup/landing_pages/useCheckoutRedirect';
import { Spinner } from '@/Spinner';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaymentApprovedPage: FC = () => {
  useAwaitActiveSubscription();
  return (
    <Container>
      <Spinner size={300} />
    </Container>
  );
};
